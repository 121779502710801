.camOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    img {
        align-self: center;
        text-align: center;
    }
}

.topBar {
    color: white;
    position: fixed;
    top: 0;
    width: 100%;

    & h5 {
        margin: 0;
    }
}

.bottomBar {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    position: fixed;
    bottom: 2%;
    width: 100%;

    & h1 {
        margin: 0;
    }
}
