$custom-theme-colors: (
    grade-1: #40a754,
    grade-2: #a1c75a,
    grade-3: #fdcd23,
    grade-4: #e67d2c,
    grade-5: #a24b42,
    grade-U: #948f8c,
    signal-lite-blue: #00aeef,
    primary: #c42034,
);

.btn-signal-lite-blue {
    color: #fff !important;
}
