img.tyreSplat {
    transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    width: 100%;
}

.tyreButton {
    position: absolute;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);

    &.front {
        left: 30%;
    }

    &.rear {
        left: 60%;
    }
}

.tyreButtonRow {
    min-height: 38px;
    position: relative;
}
