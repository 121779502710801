.container {
    max-width: 100%;
    margin: 0px;
    padding-right: 0;
    padding-left: 0;
}

.left-sidebar {
    display: block;
    position: fixed;
    width: 260px;
    height: 100%;
    transition: 0.2s ease-in;
    border-right: 2px solid #e0e0e0;
    z-index: 1;
    background-color: white;
}

.page-wrapper {
    display: block;
    margin-left: 260px;
    height: 100%;
    min-height: 100%;
}

.sidebar-nav ul .sidebar-item {
    width: 100%;
    margin-bottom: 10px;
    list-style: none;
    font-size: 1rem;
}

.sidebar-link {
    padding: 12px 15px;
    display: flex;
    border-radius: 4px;
    white-space: nowrap;
    align-items: center;
    position: relative;
    color: #000000;
    opacity: 0.3;
    font-weight: bold;
}

.sidebar-item .active {
    opacity: 1 !important;
}

.sidebar-link:hover {
    text-decoration: none;
    color: black;
    opacity: 0.6;
    transition: all 0.5s ease;
}

.sidebar-dropdown {
    bottom: 0px;
    position: fixed;
    width: 260px;
}

.sidebar-dropdown-link {
    padding: 12px 15px;
    display: flex;
    border-radius: 4px;
    white-space: nowrap;
    align-items: center;
    position: relative;
    color: #000000;
    font-weight: bold;
}

.sidebar-chevron {
    display: none;
}

@media only screen and (max-width: 768px) {
    .sidebar-chevron {
        position: absolute;
        right: -35px;
        background-color: white;
        width: 35px;
        height: 35px;
        top: 5px;
        border-top: 2px solid #e0e0e0;
        border-right: 2px solid #e0e0e0;
        border-bottom: 2px solid #e0e0e0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1em;
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;
    }
    .sidebar-chevron > .chevron {
        transition: transform 0.2s;
    }
    .page-wrapper {
        margin-left: 0px;
    }

    .left-sidebar {
        left: -260px;
        background-color: white;
    }

    .sidebar-rotate > .chevron {
        transform: rotate(180deg);
    }

    .open-sidebar {
        left: 0px;
    }
}

.inspection-nav {
    color: black;
    border-left: 5px solid white !important;
}

.inspection-nav .active > h4 {
    color: black;
}

.inspection-nav .nav-link {
    border: none !important;
    border-radius: 0px !important;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #f8f9fa !important;
}

.inspection-nav.active.nav-item {
    color: black !important;
    background-color: #f8f9fa;
}

.inspection-nav.active.nav-item.incomplete {
    border-left: 5px solid var(--bs-danger) !important;
}

.inspection-nav.active.nav-item.complete {
    border-left: 5px solid var(--bs-success) !important;
}

.inspection-nav.active.nav-item .nav-link {
    color: black !important;
}

.scroll-sidebar > .nav {
    display: block;
}
