@import "../node_modules/@g3r-developers/g3-common/dist/main.css";
@import "./Styles/scss/custom-bootstrap.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

body {
    margin: 0;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.m-0a {
    margin: 0 auto;
}

a {
    text-decoration: none !important;
}

.text-bold {
    font-weight: 900;
}

.text-x2 {
    font-size: 2rem;
}

.text-0-8 {
    font-size: 0.8rem;
}

.question-dropdown__control {
    padding: 1rem !important;
}

.mt-50 {
    margin-top: 30%;
}

.text-white {
    color: white !important;
}

.accordion {
    margin-top: 1rem;
}

.accordion-button,
.accordion-button:not(.collapsed) {
    color: #000;
    background-color: unset;
    padding: 1rem 0rem;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-body {
    padding: 1rem 0 0;
}

.logo {
    width: 20%;
    max-width: 100px;
}
