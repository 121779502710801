.damage_img_btn {
    @at-root button#{&} {
        all: unset;
        cursor: pointer;
    }

    & .damage_img_container {
        position: relative;

        & .damage_img_badge {
            left: 0;
            position: absolute;
            top: 0;
        }
    }
}
